
import Cookies from 'js-cookie';

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PwaInstall',
    props: [
        'cookiePrefix'
    ],
    data() {
        return {
            mCookieName: '-add-to-home-screen',
            mDeferredPrompt: this.initDeferredPrompt()
        }
    },
    create() {
        console.log('create() called');
        this.addEventListeners();
    },
    methods: {
        addEventListeners(): void {
            this.mCookieName = this.cookiePrefix + this.mCookieName;
            console.log("InstallPwaService-addEventListeners() called");
            // install as app
            window.addEventListener('beforeinstallprompt', e => {
                console.log("InstallPwaService() beforeinstallprompt event");
                e.preventDefault();
                // Stash the event so it can be triggered later.
                if (Cookies.get('stationboard-add-to-home-screen') === undefined) {
                    this.mDeferredPrompt = e;
                }
            });
            // install as app
            window.addEventListener('appinstalled', () => {
                console.log("InstallPwaService() appinstalled event");
                this.mDeferredPrompt = null;
            });
        },
        initDeferredPrompt(): any {
            return null;
        },
        // install as app
        async dismiss() {
            // expires in 15 days
            Cookies.set(this.mCookieName, "1", { expires: 15 });
            this.mDeferredPrompt = 0;
        },
        // install as app
        async install() {
            if (this.mDeferredPrompt) {
                this.mDeferredPrompt.prompt();
            }
        }
    }
});
