import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ConnectionList from '@/views/ConnectionList.vue'
import ConnectionDetail from '@/views/ConnectionDetail.vue'
import EditConnection from '@/views/EditConnection.vue'
import About from '@/views/About.vue'
import AddConnection from '@/views/AddConnection.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'ConnectionList',
    component: ConnectionList
  },
  {
    path: '/connection/:id',
    name: 'ConnectionDetail',
    props: true,
    component: ConnectionDetail
  },
  {
    path: '/edit/:id',
    name: 'EditConnection',
    props: true,
    component: EditConnection
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/add',
    name: 'AddConnection',
    component: AddConnection
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
