<template>
    <div v-if="mUpdateExists" class="flex w-full justify-end bg-gray-100 p-2 mb-1">
        <div class="py-2 text-gray-900">
            New version available
        </div>
        <div>
            <button class="bg-green-500 text-white ml-1 py-2 px-2 rounded" v-on:click="refreshApp">Update</button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PwaUpdate',
    data() {
        return {
            // refresh variables
            mRefreshing: false,
            mRegistration: this.initRegistration(),
            mUpdateExists: false
        }
    },
    created() {
        // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    
        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.mRefreshing) {
                return;
            }
            this.mRefreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
        });
    },
    methods: {
        initRegistration(): any {
            return 0;
        },
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event: any) {
            this.mRegistration = event.detail;
            this.mUpdateExists = true;
        },
        // Called when the user accepts the update
        refreshApp() {
            this.mUpdateExists = false;
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.mRegistration || !this.mRegistration.waiting) {
                return;
            }
            // send message to SW to skip the waiting and activate the new SW
            this.mRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    }
});
</script>