
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SectionsDetail from '@/components/SectionsDetail.vue';

export default defineComponent({
  name: 'DepartureRow',
  components: {
    SectionsDetail
  },
  props: ['departure'],
  data() {
    return {
        mShowSections: false
    }
  },
  methods: {
    getTimeUntil(dateTime : string): string {
      dayjs.extend(relativeTime);
      return dayjs(dateTime).fromNow();
    },
    getTime(dateTime : string): string {
      return dayjs(dateTime).format('HH:mm');
    },
    showSections(): void {
        this.mShowSections = !this.mShowSections;
    }
  }
});
