import { Property, convertStrToProperty } from '@/ts/interface/Property'

class PropertyStorage {
    _prefixKey = 'swiss-property-';

    public set setProperty(property: Property) {
        const key = this._prefixKey + property.key;
        localStorage.setItem(key, JSON.stringify(property));
    }
    public getByKey(key: string): Property|null {
        return this.getByKeyWithPrefix(this._prefixKey + key);
    }

    private getByKeyWithPrefix(key: string): Property|null {
        if (key.startsWith(this._prefixKey)) {
            return convertStrToProperty(localStorage.getItem(key));
        }
        return null;
    }
}

export { PropertyStorage };