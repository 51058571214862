
import { defineComponent } from 'vue';
import { ConnectionStorage } from '@/ts/ConnectionStorage'
import { Connection } from '@/ts/interface/Connection'; 
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Navbar from '@/components/Navbar.vue';

export default defineComponent({
  name: 'EditConnection',
  props: ['id'],
  components: {
      Button,
      Checkbox,
      InputText,
      Navbar
  },
  data() {
    return {
      mConnectionStorage: new ConnectionStorage(),
      mConnection: {} as Connection
    }
  },
  mounted() {
      let connection = this.mConnectionStorage.getById(this.id);
      if (connection) {
        this.mConnection = connection;
        console.log(this.mConnection);
      }
  },
  methods: {
    saveStation() {
      this.mConnectionStorage.setConnection = this.mConnection;
      this.$router.push({ name: 'ConnectionDetail', params: { id: this.id } })
    }
  }
});
