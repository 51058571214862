
import { defineComponent } from 'vue';
import { ConnectionStorage } from '@/ts/ConnectionStorage'
import { Connection } from '@/ts/interface/Connection';
import StationService from '@/services/StationService';
import { Departure } from '@/ts/interface/Departure';
import dayjs from 'dayjs';
import Navbar from '@/components/Navbar.vue';
import DigitalTime from '@/components/DigitalTime.vue';
import DepartureRow from '@/components/DepartureRow.vue';

export default defineComponent({
  name: 'ConnectionDetail',
  props: ['id'],
  components: {
    Navbar,
    DigitalTime,
    DepartureRow
  },
  data() {
    return {
      mConnectionStorage: new ConnectionStorage(),
      mConnection: {} as Connection,
      mDepartures: [] as Departure[],
      mIsSearching: false
    }
  },
  mounted() {
    let connection = this.mConnectionStorage.getById(this.id);
    if (connection) {
      this.mConnection = connection;
      this.getConnections();
    }
  },
  methods: {
    getConnections() {
      if (this.mConnection && this.mConnection.departureStation && this.mConnection.arrivalStation) {
        this.mIsSearching = true;
        let viaStationId = this.mConnection.viaStation ? this.mConnection.viaStation.stationId : null;
        StationService.getConnections(
          this.mConnection.departureStation.stationId,
          this.mConnection.arrivalStation.stationId,
          viaStationId,
          dayjs().format('YYYY-MM-DD'),
          dayjs().format('HH:mm'))
        .then(response => {
            let connections = response.data.connections;
            let departures = [] as Departure[];

            for (let connection of connections) {
                if (connection.sections.length > 0) {
                  var section = connection.sections[0];
                  if (section.journey) {
                    departures.push({
                        id: section.journey.name,
                        to: section.journey.to,
                        category: section.journey.category,
                        number: section.journey.number,
                        operator: section.journey.operator,
                        departure: connection.from.departure,
                        duration: (connection.to.arrivalTimestamp - connection.from.departureTimestamp),
                        sections: connection.sections
                    });
                  }
                }
            }
            this.setDepartures(departures);
        }).catch(error => {
            console.log(error)
        });
      }
    },
    setDepartures(departures: Departure[]): void {
        this.mIsSearching = false;
        this.mDepartures = departures;
    },
    deleteStation() {
      this.mConnectionStorage.deleteConnection(this.mConnection.id);
      this.$router.push({ name: 'ConnectionList' })
    },
    viaStationExists(): boolean {
      if (this.mConnection.viaStation) {
        return this.mConnection.viaStation.id !== '';
      }
      return false;
    }
  }
});
