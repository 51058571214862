
import { defineComponent } from 'vue';
import ConnectionCard from '@/components/ConnectionCard.vue';
import { ConnectionStorage } from '@/ts/ConnectionStorage';
import { PropertyStorage } from '@/ts/PropertyStorage';
import { GpsLocation } from '@/ts/interface/GpsLocation';
import Navbar from '@/components/Navbar.vue';
import GpsLocationService from '@/services/GpsLocationService';
import Button from 'primevue/button';
import PwaInstall from '@/components/PwaInstall.vue';
import PwaUpdate from '@/components/PwaUpdate.vue';
import DepartureSearch from '@/views/DepartureSearch.vue';

export default defineComponent({
  name: 'ConnectionList',
  components: {
    ConnectionCard,
    Navbar,
    Button,
    PwaInstall,
    PwaUpdate,
    DepartureSearch
  },
  data() {
    return {
      mConnectionStorage: new ConnectionStorage(),
      mPropertyStorage: new PropertyStorage(),
      mGpsLocationServiceGranted: false as boolean,
      mGPS_ENABLED: 'gps_enabled',
      mCurrLocation: {} as GpsLocation
    }
  },
  mounted() {
    var isGpsEnabledProp = this.mPropertyStorage.getByKey(this.mGPS_ENABLED);
    if (isGpsEnabledProp && isGpsEnabledProp.value) {
      this.handlePermission();
    }
  },
  methods: {
    handlePermission(): void {
      GpsLocationService.handlePermission(this.permissionGps);
    },
    getCurrentPosition(): void {
      GpsLocationService.getCurrentPosition(this.updateGpsLocation, this.errorGpsLocation);
    },
    disableWatchPosition(): void {
      this.mGpsLocationServiceGranted = false;
      this.mPropertyStorage.setProperty = {key: this.mGPS_ENABLED, value: 0};
    },
    updateGpsLocation(position: any): void {
      if (position && position.coords) {
        this.mCurrLocation = {lat: position.coords.latitude, lon: position.coords.longitude};
      }
    },
    errorGpsLocation(error: any): void {
      this.mGpsLocationServiceGranted = false;
    },
    permissionGps(result: any): void {
      if (result.state == 'granted') {
        this.getCurrentPosition();
        this.mGpsLocationServiceGranted = true;
        this.mPropertyStorage.setProperty = {key: this.mGPS_ENABLED, value: 1};
      } else {
        this.mGpsLocationServiceGranted = false;
        this.mPropertyStorage.setProperty = {key: this.mGPS_ENABLED, value: 0};
        this.disableWatchPosition();
      }
    }/*,
    getConnectionList(): Array<Connection> {
      let connectionList = this.mConnectionStorage.getAll;
      let compareName = function (a: Connection, b: Connection) {
        let departureStationA = a.departureStation;
        let departureStationB = b.departureStation;
        if (departureStationA && departureStationB == null) {
          return 1;
        }
        if (departureStationA == null && departureStationB) {
          return -1;
        }
        if (departureStationA && departureStationB) {
          if (departureStationA.name.toLowerCase() > departureStationB.name.toLowerCase()) { return 1; }
          if (departureStationA.name.toLowerCase() < departureStationB.name.toLowerCase()) { return -1; }
        }
        return 0;
      }
      return connectionList.sort(compareName);
    }*/
  }
});
