
export default {
    /**
     * Enable location watch. This will only work on secure contexts such as HTTPS
     * 
     * @param successCb callback function with parameter GeolocationPosition. position.coords.latitude, position.coords.longitude
     * @param errorCb callback function with parameter GeolocationPositionError. error.code, error.message
     * @returns id on success, null on service not available
     */
    enableWatchPosition(successCb: any, errorCb: any): number | null {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
            };
            return navigator.geolocation.watchPosition(position => {
                successCb(position);
            }, errorCb, options);
        }
        return null;
    },
    getCurrentPosition(successCb: any, errorCb: any): void {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
            };
            return navigator.geolocation.getCurrentPosition(position => {
                successCb(position);
            }, errorCb, options);
        }
    },
    disableWatchPosition(id: number):void {
        if (navigator.geolocation) {
            navigator.geolocation.clearWatch(id);
        }
    },
    handlePermission(permissionCb: any): void {
        navigator.permissions.query({name:'geolocation'}).then(function(result) {
            if (result.state == 'granted') {
                console.log('GpsLocationService: permission granted');
            } else if (result.state == 'prompt') {
                console.log('GpsLocationService: permission prompt');
            } else if (result.state == 'denied') {
                console.log('GpsLocationService: permission denied');
            }
            result.onchange = function() {
                permissionCb(result);
            }
            permissionCb(result);
        });
      }
}