<template>
    <tr class="row-content" v-on:click="showSections">
        <td class="cell-content align-middle font-bold pl-2">
            <span class="bg-white text-black p-2">{{ departure.category }}{{ departure.number }}</span>
        </td>
        <td class="cell-content align-middle">
            {{ departure.to }}
        </td>
        <td class="cell-content align-middle text-right">
            {{ getTime(departure.departure) }}
        </td>
        <td class="cell-content pr-2 align-middle text-right">
            {{ getTimeUntil(departure.departure) }}
        </td>
    </tr>
    <SectionsDetail :sections="departure.sections" :journeyduration="departure.duration" v-if="mShowSections && departure.duration"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import SectionsDetail from '@/components/SectionsDetail.vue';

export default defineComponent({
  name: 'DepartureRow',
  components: {
    SectionsDetail
  },
  props: ['departure'],
  data() {
    return {
        mShowSections: false
    }
  },
  methods: {
    getTimeUntil(dateTime : string): string {
      dayjs.extend(relativeTime);
      return dayjs(dateTime).fromNow();
    },
    getTime(dateTime : string): string {
      return dayjs(dateTime).format('HH:mm');
    },
    showSections(): void {
        this.mShowSections = !this.mShowSections;
    }
  }
});
</script>