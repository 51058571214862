<template>
  <div id="nav" class="flex justify-between">
    <router-link v-if="isInLinkList('AddConnection')" :to="{name: 'AddConnection'}"><i class="pi pi-plus-circle text-3xl"></i></router-link>
    <router-link v-if="isInLinkList('ConnectionList')" :to="{name: 'ConnectionList'}"><i class="pi pi-home text-3xl"></i></router-link>
    <router-link v-if="isInLinkList('ConnectionDetail')" :to="{name: 'ConnectionDetail', params: { id: linkParameters.id }}"><i class="pi pi-times text-3xl"></i></router-link>
    <router-link v-if="isInLinkList('EditConnection')" :to="{name: 'EditConnection', params: {id: linkParameters.id}}"><i class="pi pi-pencil text-3xl"></i></router-link>
  </div>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Navbar',
    props: [
        'linkList',
        'linkParameters'
    ],
    methods: {
      isInLinkList(linkName: string): boolean {
        return this.linkList.includes(linkName);
      }
    }
});
</script>