<template>
  <div class="items-center justify-center">
    <div class="w-full md:w-2/3 p-2 flex justify-between">
      <h1>Add Connection</h1>
      <Navbar :linkParameters="{}" :linkList="['ConnectionList']" />
    </div>
    <table class="w-full md:w-2/3 border-collapse">
      <tbody class="align-baseline">
        <tr class="row-content">
          <td class="cell-content font-bold pl-2">
            <label for="alias">Alias</label>
          </td>
          <td class="cell-content">
            <InputText class="w-full" type="text" v-model="mAlias"/>
          </td>
        </tr>
        <tr class="row-content">
          <td class="cell-content font-bold pl-2">
            <label>Departure time</label>
          </td>
          <td class="cell-content">
            <Dropdown v-model="mSelectedHour" :options="mHours" optionLabel="name" placeholder="HH" @change="changeHour" /><span class="mr-2 ml-2">:</span><Dropdown v-model="mSelectedMinute" :options="mMinutes" optionLabel="name" placeholder="mm" @change="changeMinute" />
          </td>
        </tr>
        <tr class="row-content">
          <td class="cell-content font-bold pl-2">
            <label for="searchDeparture">Departure</label>
          </td>
          <td class="cell-content flex">
            <AutoComplete 
              id="searchDeparture"
              v-model="mSelectedDeparture" 
              :suggestions="mFilteredDepartures" 
              @complete="searchStation($event, mDEPARTURE)"
              @item-select="generateAlias($event)"
              field="name"
              class="w-full"
            />
            <i class="pi pi-replay text-3xl" v-if="mSelectedDeparture.destinations && mSelectedDeparture.destinations.length" @click="getConnections"></i>
          </td>
        </tr>
        <tr class="row-content">
          <td class="cell-content font-bold pl-2">
            <label for="searchVia">via</label>
          </td>
          <td class="cell-content flex">
            <AutoComplete 
              id="searchVia"
              v-model="mSelectedVia" 
              :suggestions="mFilteredVia" 
              @complete="searchStation($event, mVIA)"
              @item-select="generateAlias($event)"
              field="name"
              class="w-full"
            />
            <i class="pi pi-replay text-3xl" v-if="mSelectedVia.destinations && mSelectedVia.destinations.length" @click="getConnections"></i>
          </td>
        </tr>
        <tr class="row-content">
          <td class="cell-content font-bold pl-2">
            <label for="searchArrival">Arrival</label>
          </td>
          <td class="cell-content flex">
            <AutoComplete 
              id="searchArrival"
              v-model="mSelectedArrival" 
              :suggestions="mFilteredArrivals" 
              @complete="searchStation($event, mARRIVAL)"
              @item-select="generateAlias($event)"
              field="name"
              class="w-full"
            />
          </td>
        </tr>
        <tr class="row-content">
          <!-- td class="cell-content">&nbsp;</td>
          <td class="cell-content">
            <li v-for="destination in mConnection.destinations" :key="destination.to">
              <Checkbox v-model="destination.isActive" :binary="true" />
              {{ destination.category }}{{ destination.number }}, {{ destination.to }}
            </li>
          </td>
        </tr>
        <tr class="row-content" -->
            <td class="cell-content"></td>
            <td class="cell-content">
                <Button label="Add station" class="bg-green-400 border-green-600" @click="addConnection" />
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import StationService from '@/services/StationService';
import {ConnectionStorage} from '@/ts/ConnectionStorage';
import {Station, initStation, createStation} from '@/ts/interface/Station';
import dayjs from 'dayjs';
import Navbar from '@/components/Navbar.vue';
import Dropdown from 'primevue/dropdown';
import {generateHours, generateMinutes} from '@/services/TimeService';

export default defineComponent({
    name: 'AddConnection',
    components: {
      AutoComplete,
      Button,
      InputText,
      Navbar,
      Dropdown
    },
    data() {
        return {
            mDEPARTURE: 'departure',
            mARRIVAL: 'arrival',
            mVIA: 'via',
            mShowSearchConnectionsBtn: false,
            mSelectedDeparture: initStation(),
            mSelectedArrival: initStation(),
            mSelectedVia: initStation(),
            mAlias: '',
            mFilteredDepartures: [] as Station[],
            mFilteredArrivals: [] as Station[],
            mFilteredVia: [] as Station[],
            mConnectionStorage: new ConnectionStorage(),
            mTimepickerValue: dayjs().format('HH:mm'),
            mSelectedHour: {},
            mSelectedHourStr: '',
            mHours: generateHours(),
            mSelectedMinute: {},
            mSelectedMinuteStr: '',
            mMinutes: generateMinutes()
        }
    },
    computed: {
    },
    mounted() {
        var hour = dayjs().format('HH');
        var minute = dayjs().format('mm');
        this.mSelectedHour = {'name': hour, 'value': hour};
        this.mSelectedHourStr = hour;
        this.mSelectedMinute = {'name': minute, 'value': minute};
        this.mSelectedMinuteStr = minute;
    },
    methods: {
        generateAlias(event: any): void {
          var aliasList = [];
          if (this.mSelectedDeparture.name !== '') {
            aliasList.push(this.mSelectedDeparture.name);
          }
          if (this.mSelectedArrival.name !== '') {
            aliasList.push(this.mSelectedArrival.name);
          }
          this.mAlias = aliasList.join(' - ');
        },
        searchStation(event: any, direction: string) {
            StationService.searchStation(event.query)
                .then(response => {
                    var filtered = [] as Station[];
                    for (let station of response.data.stations) {
                        let id = String(dayjs().unix());
                        let s = createStation(id, station);
                        filtered.push(s);
                    }
                    if (direction === this.mDEPARTURE) {
                        this.mFilteredDepartures = filtered;
                    } else if (direction === this.mARRIVAL) {
                        this.mFilteredArrivals = filtered;
                    } else if (direction === this.mVIA) {
                        this.mFilteredVia = filtered;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addConnection() {
            this.mConnectionStorage.setConnection = { 
                id: this.mSelectedDeparture.id + '-' + this.mSelectedArrival.id,
                alias: this.mAlias,
                departureStation: this.mSelectedDeparture, 
                arrivalStation: this.mSelectedArrival,
                viaStation: this.mSelectedVia
            };
            this.$router.push({ name: 'ConnectionList' })
        },
        changeHour(eventObj: any) {
            this.mSelectedHourStr = eventObj.value.value;
        },
        changeMinute(eventObj: any) {
            this.mSelectedMinuteStr = eventObj.value.value;
        },
        getSelectedHourMinute(): string {
            var selectedHourMinute = dayjs().format('YYYY-MM-DD') + ' ' + this.mSelectedHourStr + ':' + this.mSelectedMinuteStr;
            return selectedHourMinute;
        }
    }
});
</script>
