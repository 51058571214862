import {Station} from '@/ts/interface/Station';

interface Connection {
    id: string,
    alias: string,
    departureStation: Station|null,
    arrivalStation: Station|null,
    viaStation: Station|null
}

function initConnection(): Connection {
    return {
        id: '',
        alias: '',
        departureStation: null,
        arrivalStation: null,
        viaStation: null
    }
}

function convertConnectionToStr(connection: Connection): string {
    return JSON.stringify(connection);
}

function convertStrToConnection(connectionStr: string | null): Connection|null {
    if (connectionStr) {
      const connectionArr = JSON.parse(connectionStr);
      return {
          id: connectionArr.id,
          alias: connectionArr.alias,
          departureStation: connectionArr.departureStation,
          arrivalStation: connectionArr.arrivalStation,
          viaStation: connectionArr.viaStation
        };
    }
    return null;
}
export { Connection, initConnection, convertConnectionToStr, convertStrToConnection }