
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export default defineComponent({
  name: 'SectionsDetail',
  components: {

  },
  props: ['sections', 'journeyduration'],
  data() {
    return {
    }
  },
  methods: {
    formatDatetime(datetime: string): string {
      return dayjs(datetime).format('HH:mm')
    },
    formatDuration(): string {
      dayjs.extend(duration);
      return dayjs.duration(this.journeyduration, 'seconds').format('H [hour] m [mins]');
    }
  }

});
