import axios, { AxiosResponse } from 'axios';

const apiClient = axios.create({
  baseURL: (process.env.NODE_ENV === 'production') ? '/' : 'http://transport.opendata.ch/v1/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  searchStation(term: string): Promise<AxiosResponse<any>> {
    const url = (process.env.NODE_ENV === 'production') ? 'getLocations.php' : 'locations'
    return apiClient.get(url, {
      params: {
        query: term, 
        type: 'station'
      }
    })
  },
  getDestinations(stationId: string): Promise<AxiosResponse<any>> {
    const url = (process.env.NODE_ENV === 'production') ? 'getStationboard.php' : 'stationboard'
    return apiClient.get(url, {
      params: {
        id: stationId,
        limit: 15
      }
    })
  },
  getConnections(stationIdStart: string, stationIdEnd: string, stationIdVia: string|null, date: string, time: string): Promise<AxiosResponse<any>> {
    const url = (process.env.NODE_ENV === 'production') ? 'getConnections.php' : 'connections';

    if (stationIdVia) {
      const params = {
        from: stationIdStart,
        to: stationIdEnd,
        date: date,
        time: time,
        via: stationIdVia
      };
      return apiClient.get(url, {
        params: params
      });
    }
    const params = {
      from: stationIdStart,
      to: stationIdEnd,
      date: date,
      time: time
    };
    return apiClient.get(url, {
      params: params
    });
  }
}