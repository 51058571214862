<template>
    <div>
        {{ mNow }}
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'DigitalTime',
    data() {
        return {
            mTimerId: 0,
            mNow: ''
        }
    },
    mounted() {
        this.mTimerId = setInterval(this.updateTime, 1000);
    },
    methods: {
        updateTime() {
            this.mNow = dayjs().format('HH:mm:ss');
        }
    }
});
</script>