<template>
  <tr v-for="(section, key) in sections" :key="key" class="pb-2">
    <td colspan="4" class="pl-2">
      <div>
        <span class="bg-white text-black p-1 font-bold">{{ section.journey.category }}{{ section.journey.number }}</span> to {{ section.journey.to }} <span v-if="section.journey.passList[0].platform">on track {{ section.journey.passList[0].platform }}</span>
      </div>
      <div>
        {{ formatDatetime(section.departure.departure) }} {{ section.departure.station.name}} <i class="pi pi-arrow-right" /> {{ section.arrival.station.name }} {{ formatDatetime(section.arrival.arrival) }}
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="4" class="pl-2 pb-2">
      Duration: {{ formatDuration()}}
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export default defineComponent({
  name: 'SectionsDetail',
  components: {

  },
  props: ['sections', 'journeyduration'],
  data() {
    return {
    }
  },
  methods: {
    formatDatetime(datetime: string): string {
      return dayjs(datetime).format('HH:mm')
    },
    formatDuration(): string {
      dayjs.extend(duration);
      return dayjs.duration(this.journeyduration, 'seconds').format('H [hour] m [mins]');
    }
  }

});
</script>