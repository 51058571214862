<template>
    <div class="w-full md:w-2/3 p-2 flex justify-between">
      <h1>Edit connection</h1>
      <Navbar :linkParameters="{'id': this.id}" :linkList="['ConnectionDetail']" />
    </div>
    
    <div v-if="mConnection" class="flex">
      <div class="p-1">
        {{ mConnection.name }}
      </div>
      <div class="p-1">
        <InputText type="text" v-model="mConnection.alias"/>
      </div>
      <div class="p-1" v-if="mConnection.destinations && mConnection.destinations.length">
        <h2>Destinations</h2>
        <ul>
          <li v-for="destination in mConnection.destinations" :key="destination.to">
            <Checkbox v-model="destination.isActive" :binary="true" />
            {{ destination.category }}{{ destination.number }}, {{ destination.to }}
          </li>
        </ul>
      </div>

      <div class="p-1">
        <Button label="Save Station" @click="saveStation"/>
      </div>
    </div>
    <div v-if="mConnection">
      <div class="p-1" v-if="mConnection.departureStation">
        {{ mConnection.departureStation.name }}, {{ mConnection.departureStation.stationId }}
      </div>
      <div class="p-1" v-if="mConnection.arrivalStation">
        {{ mConnection.arrivalStation.name }}, {{ mConnection.arrivalStation.stationId }}
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ConnectionStorage } from '@/ts/ConnectionStorage'
import { Connection } from '@/ts/interface/Connection'; 
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Navbar from '@/components/Navbar.vue';

export default defineComponent({
  name: 'EditConnection',
  props: ['id'],
  components: {
      Button,
      Checkbox,
      InputText,
      Navbar
  },
  data() {
    return {
      mConnectionStorage: new ConnectionStorage(),
      mConnection: {} as Connection
    }
  },
  mounted() {
      let connection = this.mConnectionStorage.getById(this.id);
      if (connection) {
        this.mConnection = connection;
        console.log(this.mConnection);
      }
  },
  methods: {
    saveStation() {
      this.mConnectionStorage.setConnection = this.mConnection;
      this.$router.push({ name: 'ConnectionDetail', params: { id: this.id } })
    }
  }
});
</script>