import { Connection, convertStrToConnection } from '@/ts/interface/Connection'

class ConnectionStorage {
    _prefixKey = 'swiss-connection-';

    public set setConnection(connection: Connection) {
        const id = connection.id;
        const key = this._prefixKey + id;
        localStorage.setItem(key, JSON.stringify(connection));
    }

    public deleteConnection(id: string): void {
        const key = this._prefixKey + id;
        localStorage.removeItem(key);
    }

    public getById(id: string): Connection|null {
        const key = this._prefixKey + id;
        return this.getByIdWithPrefix(key);
    }

    private getByIdWithPrefix(id: string): Connection|null {
        if (id.startsWith(this._prefixKey)) {
            return convertStrToConnection(localStorage.getItem(id));
        }
        return null;
    }

    public get getAll(): Array<Connection> {
        const values = [];
        const keys = Object.keys(localStorage);
        let len = keys.length;

        while (len--) {
            const item = this.getByIdWithPrefix(keys[len]);
            if (item) {
                values.push(item);
            }
        }
        return values;
    }

    public get getSize(): number
    {
        const all = this.getAll;
        return all.length;
    }
}

export { ConnectionStorage };