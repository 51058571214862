
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Navbar',
    props: [
        'linkList',
        'linkParameters'
    ],
    methods: {
      isInLinkList(linkName: string): boolean {
        return this.linkList.includes(linkName);
      }
    }
});
