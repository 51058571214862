function generateHours(): Array<any> {
    const hours : Array<any> = [];
    for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0');
        hours.push({'name': hour, 'value': hour});
    }
    return hours;
}

function generateMinutes(): Array<any> {
    const minutes : Array<any> = [];
    for (let i = 0; i < 60; i++) {
        const minute = i.toString().padStart(2, '0');
        minutes.push({'name': minute, 'value': minute});
    }
    return minutes;
}

export {generateHours, generateMinutes}
