<template>
  <tr v-if="connection">
      <td class="cell-content align-middle">
        <i v-if="!isNear && currLocation" class="pl-2 pr-2 pi pi-flag text-2xl"></i>
        <i v-if="isNear && currLocation" class="pl-2 pr-2 pi pi-flag text-2xl text-transportYellow"></i>
      </td>
      <td class="cell-content font-bold align-middle">
        <router-link v-bind:to="{name: 'ConnectionDetail', params: { id: connection.id }}">{{ connection.alias }}</router-link>
        <div v-if="viaStationExists()" class="font-normal text-sm">via {{ connection.viaStation.name }}</div>
      </td>
      <td class="cell-content pr-2 align-middle text-right">
        <router-link v-bind:to="{name: 'ConnectionDetail', params: { id: connection.id }}"><i class="pl-2 pi pi-chevron-right"></i></router-link>
      </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { distanceTo } from '@/ts/interface/GpsLocation';

export default defineComponent({
  name: 'ConnectionCard',
  components: {

  },
  props: [
    'connection',
    'currLocation',
    'locationGranted'
  ],
  data() {
    return {
      mNEAR: 1,
      mINFINITY: 100000
    }
  },
  computed: {
    distance: function(): number {
      if (this.currLocation && this.locationGranted) {
        return distanceTo(this.currLocation, this.connection.departureStation.location);
      }
      return this.mINFINITY;
    },
    isNear: function(): boolean {
      return this.distance <= this.mNEAR;
    }
  },
  methods: {
    viaStationExists(): boolean {
      if (this.connection.viaStation) {
        return this.connection.viaStation.id !== '';
      }
      return false;
    }
  }
});
</script>
