interface GpsLocation {
    // cast: myNumber: number = Number(myNumberString);
    lat: number,
    lon: number
}

function degToRad(deg: number): number {
    return deg * (Math.PI / 180.0);
}

function radToDeg(rad: number): number {
    return rad * (180/Math.PI);
}

function distanceTo(from: GpsLocation, to: GpsLocation): number {
    if (from.lat === to.lat && from.lon === to.lon) {
        return 0;
    }
    const fromLat = from.lat;
    const fromLon = from.lon;
    const toLat = to.lat;
    const toLon = to.lon;

    const theta = fromLon - toLon;
    let dist = Math.sin(degToRad(fromLat)) * Math.sin(degToRad(toLat)) + Math.cos(degToRad(fromLat)) * Math.cos(degToRad(toLat)) * Math.cos(degToRad(theta));
    dist = Math.acos(dist);
    dist = radToDeg(dist);
    dist = dist * 60 * 1.1515;
    // kilometres
    return dist * 1.609344;
}

export { GpsLocation, distanceTo }