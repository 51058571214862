
import { defineComponent } from 'vue';
import { distanceTo } from '@/ts/interface/GpsLocation';

export default defineComponent({
  name: 'ConnectionCard',
  components: {

  },
  props: [
    'connection',
    'currLocation',
    'locationGranted'
  ],
  data() {
    return {
      mNEAR: 1,
      mINFINITY: 100000
    }
  },
  computed: {
    distance: function(): number {
      if (this.currLocation && this.locationGranted) {
        return distanceTo(this.currLocation, this.connection.departureStation.location);
      }
      return this.mINFINITY;
    },
    isNear: function(): boolean {
      return this.distance <= this.mNEAR;
    }
  },
  methods: {
    viaStationExists(): boolean {
      if (this.connection.viaStation) {
        return this.connection.viaStation.id !== '';
      }
      return false;
    }
  }
});
