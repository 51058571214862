
import { defineComponent } from 'vue';
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import StationService from '@/services/StationService';
import {Station, initStation, createStation} from '@/ts/interface/Station';
import dayjs from 'dayjs';
import { Departure } from '@/ts/interface/Departure';
import DepartureRow from '@/components/DepartureRow.vue';

export default defineComponent({
    name: 'DepartureSearch',
    components: {
        Button,
        AutoComplete,
        DepartureRow
    },
    data() {
        return {
            mFilteredDepartures: [] as Station[],
            mSelectedDeparture: initStation(),
            mDepartures: [] as Departure[],
            mIsSearching: false
        }
    },
    methods: {
        clear() {
            this.mSelectedDeparture = initStation();
            this.mDepartures = [];
            this.mFilteredDepartures = [];
        },
        searchStation(event: any) {
            this.mIsSearching = true;
            StationService.searchStation(event.query)
                .then(response => {
                    var filtered = [] as Station[];
                    for (let station of response.data.stations) {
                        let id = String(dayjs().unix());
                        let s = createStation(id, station);
                        filtered.push(s);
                    }
                    this.mFilteredDepartures = filtered;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getDestinations() {
            if (this.mSelectedDeparture && this.mSelectedDeparture.stationId) {
                StationService.getDestinations(this.mSelectedDeparture.stationId)
                .then(response => {
                    let stationboard = response.data.stationboard;
                    let departures = [] as Departure[];

                    for (let connection of stationboard) {
                        var stop = connection.stop;
                            departures.push({
                                id: connection.name,
                                to: connection.to,
                                category: connection.category,
                                number: connection.number,
                                operator: connection.operator,
                                departure: stop.departure,
                                duration: 0,
                                sections: []
                            });
                    }
                    this.setDepartures(departures);
                }).catch(error => {
                    console.log(error)
                });
            }
        },
        setDepartures(departures: Departure[]): void {
            this.mIsSearching = false;
            this.mDepartures = departures;
        },
    }
});
