import { GpsLocation } from "./GpsLocation";

interface Station {
    id: string,
    location: GpsLocation,
    stationId: string, // "8591373"
    name: string //"Zürich, Sonneggstrasse"
}

function initStation(): Station {
    return {
        id: '',
        location: {lat: 0, lon: 0},
        stationId: '',
        name: ''
    };
}

function convertStrToStation(stationStr: string | null): Station|null {
    if (stationStr) {
      const stationArr = JSON.parse(stationStr);
      return {
          location: {lat: stationArr.x, lon: stationArr.y },
          id: stationArr.id, 
          stationId: stationArr.stationId,
          name: stationArr.name
        };
    }
    return null;
}

function convertStationToStr(station: Station): string {
    return JSON.stringify(station);
}

function createStation(id: string, stationObj: any): Station {
    return {
        location: {lat: stationObj.coordinate.x, lon: stationObj.coordinate.y},
        id: id, 
        stationId: stationObj.id, 
        name: stationObj.name
    }
}

export { Station, initStation, convertStrToStation, convertStationToStr, createStation }