<template>
    <div v-if="mConnection" class="w-full md:w-2/3 flex p-2 justify-between">
        <div>
          <h1>{{ mConnection.alias }}</h1>
          <div v-if="viaStationExists()" class="text-sm">via {{ mConnection.viaStation.name }}</div>
        </div>
        <DigitalTime />
    </div>
    <div class="p-2" v-if="mIsSearching">
      <i class="pi pi-spin pi-spinner"></i>
    </div>
    <div class="p-2" v-if="mDepartures && mDepartures.length == 0 && !mIsSearching">
      No connections found
    </div>
    <div v-if="mDepartures && mDepartures.length">
      <table class="w-full md:w-2/3 text-left border-collapse">
        <tbody class="align-baseline">
          <template v-for="departure in mDepartures" :key="departure.id">
            <DepartureRow :departure="departure" />
          </template>
        </tbody>
      </table>
    </div>
    <div class="w-full md:w-2/3 flex p-2 justify-between">
      <Navbar :linkParameters="{'id':id}" :linkList="['EditConnection']" />
      <i class="pi pi-trash text-3xl" @click="deleteStation"></i>
      <Navbar :linkParameters="{'id':id}" :linkList="['ConnectionList']" />
    </div>
    <div class="w-full md:w-2/3 flex justify-evenly">
      <i class="rounded-full p-3 bg-yellow-400 pi pi-replay text-gray-700 text-3xl" @click="getConnections"></i>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ConnectionStorage } from '@/ts/ConnectionStorage'
import { Connection } from '@/ts/interface/Connection';
import StationService from '@/services/StationService';
import { Departure } from '@/ts/interface/Departure';
import dayjs from 'dayjs';
import Navbar from '@/components/Navbar.vue';
import DigitalTime from '@/components/DigitalTime.vue';
import DepartureRow from '@/components/DepartureRow.vue';

export default defineComponent({
  name: 'ConnectionDetail',
  props: ['id'],
  components: {
    Navbar,
    DigitalTime,
    DepartureRow
  },
  data() {
    return {
      mConnectionStorage: new ConnectionStorage(),
      mConnection: {} as Connection,
      mDepartures: [] as Departure[],
      mIsSearching: false
    }
  },
  mounted() {
    let connection = this.mConnectionStorage.getById(this.id);
    if (connection) {
      this.mConnection = connection;
      this.getConnections();
    }
  },
  methods: {
    getConnections() {
      if (this.mConnection && this.mConnection.departureStation && this.mConnection.arrivalStation) {
        this.mIsSearching = true;
        let viaStationId = this.mConnection.viaStation ? this.mConnection.viaStation.stationId : null;
        StationService.getConnections(
          this.mConnection.departureStation.stationId,
          this.mConnection.arrivalStation.stationId,
          viaStationId,
          dayjs().format('YYYY-MM-DD'),
          dayjs().format('HH:mm'))
        .then(response => {
            let connections = response.data.connections;
            let departures = [] as Departure[];

            for (let connection of connections) {
                if (connection.sections.length > 0) {
                  var section = connection.sections[0];
                  if (section.journey) {
                    departures.push({
                        id: section.journey.name,
                        to: section.journey.to,
                        category: section.journey.category,
                        number: section.journey.number,
                        operator: section.journey.operator,
                        departure: connection.from.departure,
                        duration: (connection.to.arrivalTimestamp - connection.from.departureTimestamp),
                        sections: connection.sections
                    });
                  }
                }
            }
            this.setDepartures(departures);
        }).catch(error => {
            console.log(error)
        });
      }
    },
    setDepartures(departures: Departure[]): void {
        this.mIsSearching = false;
        this.mDepartures = departures;
    },
    deleteStation() {
      this.mConnectionStorage.deleteConnection(this.mConnection.id);
      this.$router.push({ name: 'ConnectionList' })
    },
    viaStationExists(): boolean {
      if (this.mConnection.viaStation) {
        return this.mConnection.viaStation.id !== '';
      }
      return false;
    }
  }
});
</script>