
import { defineComponent } from 'vue';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import StationService from '@/services/StationService';
import {ConnectionStorage} from '@/ts/ConnectionStorage';
import {Station, initStation, createStation} from '@/ts/interface/Station';
import dayjs from 'dayjs';
import Navbar from '@/components/Navbar.vue';
import Dropdown from 'primevue/dropdown';
import {generateHours, generateMinutes} from '@/services/TimeService';

export default defineComponent({
    name: 'AddConnection',
    components: {
      AutoComplete,
      Button,
      InputText,
      Navbar,
      Dropdown
    },
    data() {
        return {
            mDEPARTURE: 'departure',
            mARRIVAL: 'arrival',
            mVIA: 'via',
            mShowSearchConnectionsBtn: false,
            mSelectedDeparture: initStation(),
            mSelectedArrival: initStation(),
            mSelectedVia: initStation(),
            mAlias: '',
            mFilteredDepartures: [] as Station[],
            mFilteredArrivals: [] as Station[],
            mFilteredVia: [] as Station[],
            mConnectionStorage: new ConnectionStorage(),
            mTimepickerValue: dayjs().format('HH:mm'),
            mSelectedHour: {},
            mSelectedHourStr: '',
            mHours: generateHours(),
            mSelectedMinute: {},
            mSelectedMinuteStr: '',
            mMinutes: generateMinutes()
        }
    },
    computed: {
    },
    mounted() {
        var hour = dayjs().format('HH');
        var minute = dayjs().format('mm');
        this.mSelectedHour = {'name': hour, 'value': hour};
        this.mSelectedHourStr = hour;
        this.mSelectedMinute = {'name': minute, 'value': minute};
        this.mSelectedMinuteStr = minute;
    },
    methods: {
        generateAlias(event: any): void {
          var aliasList = [];
          if (this.mSelectedDeparture.name !== '') {
            aliasList.push(this.mSelectedDeparture.name);
          }
          if (this.mSelectedArrival.name !== '') {
            aliasList.push(this.mSelectedArrival.name);
          }
          this.mAlias = aliasList.join(' - ');
        },
        searchStation(event: any, direction: string) {
            StationService.searchStation(event.query)
                .then(response => {
                    var filtered = [] as Station[];
                    for (let station of response.data.stations) {
                        let id = String(dayjs().unix());
                        let s = createStation(id, station);
                        filtered.push(s);
                    }
                    if (direction === this.mDEPARTURE) {
                        this.mFilteredDepartures = filtered;
                    } else if (direction === this.mARRIVAL) {
                        this.mFilteredArrivals = filtered;
                    } else if (direction === this.mVIA) {
                        this.mFilteredVia = filtered;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addConnection() {
            this.mConnectionStorage.setConnection = { 
                id: this.mSelectedDeparture.id + '-' + this.mSelectedArrival.id,
                alias: this.mAlias,
                departureStation: this.mSelectedDeparture, 
                arrivalStation: this.mSelectedArrival,
                viaStation: this.mSelectedVia
            };
            this.$router.push({ name: 'ConnectionList' })
        },
        changeHour(eventObj: any) {
            this.mSelectedHourStr = eventObj.value.value;
        },
        changeMinute(eventObj: any) {
            this.mSelectedMinuteStr = eventObj.value.value;
        },
        getSelectedHourMinute(): string {
            var selectedHourMinute = dayjs().format('YYYY-MM-DD') + ' ' + this.mSelectedHourStr + ':' + this.mSelectedMinuteStr;
            return selectedHourMinute;
        }
    }
});
