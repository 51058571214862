interface Property {
    // cast: myNumber: number = Number(myNumberString);
    key: string,
    value: any
}

function convertStrToProperty(propertyStr: string | null): Property|null {
    if (propertyStr) {
      const propertyArr = JSON.parse(propertyStr);
      return {
          key: propertyArr.key, 
          value: propertyArr.value
        };
    }
    return null;
}

function convertPropertyToStr(property: Property): string {
    return JSON.stringify(property);
}

export { Property, convertStrToProperty, convertPropertyToStr }